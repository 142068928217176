import { environment } from "../environments/environment";

const API_RELATORIO_FRT = environment.API; // ASSIM DÁ PRA COLOCAR UMA API OPCIONAL PARA A FRT.

console.log(environment)

// NOVO MÉTODO DE BUILD (O ARQUIVO STYLE.SCSS TAMBÉM É TROCADO NESSE MÉTODO);

// **CASO NÃO QUEIRA UTILIZAR OS ENVINROMENTS É SÓ USAR O @app.config.ts**
// **É SÓ MUDAR O NOME DESSE app.config.ts E RETIRAR O @ DO OUTRO app.config.ts**
// **ABAIXO DESSE APPCONFIG ESTÁ AS CONFIGURAÇÕES DOS ENVINRONMENTS**
// **SE DAR APENAS ng-serve VOCÊ IRÁ UTILIZAR O ENVIRONMENT.TS PADRÃO**

// *** RECEPTIVOS ***
// APENAS TROQUE A API NO envinroment.receptivo.ts.
// Comando para build = npm run build:receptivo
// Comando para local = ng serve --configuration=receptivo
// Todos os receptivos feitos por min tem o seu próprio env,
// para rodar use o comando acima com o nome do receptivo

// *** FRT // ARKBEDS // HOMOLOGA // PY ***
// configs no envinroment.(frt // arkbeds // homologa/ frt-py).ts.
// Comando para build = npm run build: (frt/arkbeds/homologa/frt-py)
// Comando para local = ng serve --configuration=(frt/arkbeds/homologa/frt-py)

// *** MASTEROP // FOCO // MAGICWAY // POINT // OIKOS // GOLFINHOTUR ***
// APENAS TROQUE A API NO envinroment.genius.ts.
// Comando para build = npm run build:genius
// Comando para local = ng serve --configuration=genius

// **** MÉTODO DE FUNCIONAMENTO: *****
// O ARQUIVO ENVIRONMENT.TS É TROCADO DURANTE O NG SERVE/BUILD NA HORA DA COMPILAÇÃO
// PARA FAZER ALTERAÇÕES É SÓ IR NO ENVINRONMENT DO PROJETO QUE VAI TRABALHAR E TROCAR NORMALMENTE IGUAL ANTES
// SE ADICIONAR OU REMOVER ALGUMA *VARIAVEL* EM UM ENVIRONMENT É NECESSÁRIO MUDAR EM TODOS.
// O ARQUIVO STYLE.SCSS TAMBÉM É TROCADO DURANTE O BUILD/SERVE
// CASO QUEIRA TROCAR OUTROS ARQUIVOS (INDEX,SCSS,ICONS) É SÓ MUDAR NO angular.json EM (fileReplacements)
// TEM EXEMPLOS DE TROCA DE ICONS E INDEX NO DA FRT E NO DA ARKBEDS, VAI EM CONFIGURATIONS DO angular.json
// E PROCURA ARK/FRT PARA VER COMO FUNCIONA.

export const AMBIENTE = environment.ambiente;
export const API = environment.API;
export const VALIDAPRAZOCANCELAMENTOMANUTENCAO = environment.VALIDAPRAZOCANCELAMENTOMANUTENCAO;
export const URLSITE = environment.URLSITE; // Link na tela inicial de cadastro (apenas arkbeds)
export const THEME = environment.THEME; // CUSTOMIZA A TELA DE LOGIN, POSSIVEIS VALORES = FRT / FRT_BLACK / ARKBEDS (SE DEIXAR VAZIO USA O MODELO PADRÃO DO NEBULAR)
export const USA_AEREO = environment.USA_AEREO; //Permite ou não acesso ao aéreo no buscador
export const USA_TURISMO = environment.USA_TURISMO; // Permite ou não acesso ao módulo de turismo
export const USA_CONTRATO = environment.USA_CONTRATO; // Permite ou não acesso ao gerador de chave para o contrato
export const USA_SEGURO = environment.USA_SEGURO;
export const USA_DISNEY = environment.USA_DISNEY; //Permite ou não acesso ao módulo de Disney FRT
export const USA_CASAMENTO = environment.USA_CASAMENTO; //Permite ou não acesso ao módulo de casamentos FRT
export const USA_LOJA = environment.USA_LOJA; //Permite ou não acesso ao módulo de Loja
export const USA_PACOTE = environment.USA_PACOTE; //Permite ou não acesso ao módulo de Pacote com Omminibees
export const USA_CARRO = environment.USA_CARRO; //Permite ou não acesso ao módulo de Carros
export const MAXIMO_QUARTOS = environment.MAXIMO_QUARTOS;
export const USA_LAMINAS = environment.USA_LAMINAS; //permite o uso do sistema de laminas;
export const USA_INTRANET = environment.USA_INTRANET; //permite o uso da intranet;
export const USA_BLOQUEIO_FRT = environment.USA_BLOQUEIO_FRT; // Por enquanto usado pelo PY e INTER
export const USA_CIRCUITO = environment.USA_CIRCUITO; //Permite ou não acesso ao módulo de Circuitos, em desenvolvimento...
export const USA_CASA = environment.USA_CASA; //Permite ou não acesso ao módulo de Casa, em desenvolvimento...
export const USA_BANNERS = environment.USA_BANNERS;
export const USA_HOTEL = environment.USA_HOTEL;
export const USA_BETO = environment.USA_BETO; //Permite ou não acesso ao módulo de Beto Carrero
export const EMPRESAS = environment.EMPRESAS;
export const GERENCIAMENTO = environment.GERENCIAMENTO;
export const TURISMO = environment.TURISMO;
export const TURISMO_AEREO = environment.TURISMO_AEREO;
export const TURISMO_HOTEL = environment.TURISMO_HOTEL;
export const API_RELATORIOS = environment.ambiente == "FRT" ? API_RELATORIO_FRT : environment.API;
// export const TURISMO_AEREO = 'https://apiaereo.comprefacil.tur.br';
// export const TURISMO_HOTEL = 'https://apihotel.comprefacil.tur.br';
export const TURISMO_SERVICO = environment.TURISMO_SERVICO;
export const TURISMO_SEGURO = environment.TURISMO_SEGURO;
export const TURISMO_CARRO = environment.TURISMO_CARRO;
export const USA_PROMOCIONAL = environment.USA_PROMOCIONAL;
export const USA_MARKUPIMPRESSAO = environment.USA_MARKUPIMPRESSAO;
export const USA_ARKBEDS = environment.USA_ARKBEDS;
export const USA_TERMOMETRO = environment.USA_TERMOMETRO;
export const USA_SOLICITARDESCONTO = environment.USA_SOLICITARDESCONTO;
export const USA_CADASTROAGENCIA = environment.USA_CADASTROAGENCIA;
export const USA_CADASTRO_CIRCUITO = environment.USA_CADASTRO_CIRCUITO;

// caracteres especiais / | \ + * - . , ][ } { @$ % & ) (![] { } () # = _ > <?° §
export const TAG_GOOGLE = environment.TAG_GOOGLE;
export const MAPBOX_ACCESSTOKEN = environment.MAPBOX_ACCESSTOKEN;
// export const TAG_GOOGLE = 'GTM-NDQJC4N';
// export const MAPBOX_ACCESSTOKEN = 'pk.eyJ1IjoiZnJ0b3BlcmFkb3JhIiwiYSI6ImNsN3E5a3N0YTAycm00MW8wd2J5NXFoMjIifQ.NDfY7OK_ygHR567e-TVVcw';
export const CLIENT_ID = environment.CLIENT_ID;
export const ITENSPORPAGINA = environment.ITENSPORPAGINA;
export const USA_PACOTESIMPLIFICADO = environment.USA_PACOTESIMPLIFICADO; //Simplifica cadastro de pacote para a FRT deixa igual cadastro disney !!!!Nâo usar!!!!!!
export const ASSISTCARDLATAM = environment.ambiente == "FRT-PY";


